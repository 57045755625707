import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import TableComponent from "../../../Component/TableComponent";
import Api from "../../../Api";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import { Assignment, Check, Warning, Send, Info, ErrorOutline } from "@material-ui/icons";
import { getRoutePathname } from "../../../Config/Route";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, InputLabel, LinearProgress, Select, Typography, makeStyles } from "@material-ui/core";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import TitleComponent from "../../../Component/TitleComponent";
import FilterListIcon from "@material-ui/icons/FilterList";
import Slide from "@material-ui/core/Slide";
import Grid from "@material-ui/core/Grid";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import SelectComponent from "../../../Component/SelectComponent";
import { TableReload } from "../../../Action/TableAction";
import { cacheTablePage, cacheTableServer } from "../../../Cache";
import { inArray } from "../../../Tool/ArrayCommon";
import { green } from '@material-ui/core/colors';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import ButtonComponent from '../../../Component/ButtonComponent';
import DatePickerComponent from '../../../Component/DatePickerComponent';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../Action/LinearProgressMainLayoutAction';
import { SnackbarOpen } from '../../../Action/SnackbarAction';
import { KeyboardDatePicker } from '@material-ui/pickers';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

function LinearProgressWithLabel(props) {

    const getColor = (value) => {
        if (value >= 0 && value < 33) {
            return '#dc3545';
        }
        else if (value >= 33 && value < 66) {
            return '#db770a';
        }
        else if (value >= 66) {
            return '#28a745';
        }
    };
    const useStyles = makeStyles({
        root: {
            backgroundColor: '#e5e3e3',
            '& .MuiLinearProgress-barColorPrimary': { backgroundColor: getColor(props.value) }
        }
    });
    const classes = useStyles();

    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress className={classes.root} variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

function PersonalRelaunchList(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [optionOrganisms, setOptionOrganisms] = React.useState([]);
    const [optionFormationGroup, setOptionFormationGroup] = React.useState([]);
    const [optionPersonalPaymentType, setOptionPersonalPaymentType] = React.useState([]);
    const [isTall, setIsTall] = React.useState(true);
    const [show, setShow] = React.useState(false);
    const [row, setRow] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
    const [optionStates, setOptionStates] = React.useState([
        { value: 'all', label: 'Tous' },
        { value: 1, label: 'Facturé' },
        { value: 2, label: 'Non Facturé' },
        { value: 3, label: 'En litige' }
    ]);
    const [startAt, setStartAt] = React.useState(moment());
    const [endAt, setEndAt] = React.useState(null);
    const [period, setPeriod] = React.useState(0);
    const [textSearchSlider, setTextSearchSlider] = React.useState('');
    const [loadDownload, setLoadDownload] = React.useState(false);

    // Table
    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "organismName",
            label: "Organisme",
            options: { filter: true, sort: true }
        },
        {
            name: "username",
            label: "Nom",
            options: { filter: true, sort: true }
        },
        {
            name: "email",
            label: "Email",
            options: { filter: true, sort: true }
        },
        {
            name: "phone",
            label: "Téléphone",
            options: { filter: true, sort: true }
        },
        {
            name: "groupFormationName",
            label: "Groupe de formation",
            options: { filter: true, sort: true }
        },
        {
            name: "formationName",
            label: "Intitulé de formation",
            options: { filter: false, sort: true }
        },
        {
            name: "completion",
            label: "Complétion",
            options: { filter: false, sort: true }
        },
        {
            name: "availableAt",
            label: "Date de début",
            options: { filter: true, sort: true }
        },
        {
            name: "availableEndAt",
            label: "Date de fin",
            options: { filter: true, sort: true }
        },
        {
            name: "deletedAt",
            label: "Date de suppression",
            options: { filter: true, sort: true }
        },
        {
            name: "cancelAt",
            label: "Date de desinscription",
            options: { filter: true, sort: true }
        },
        {
            name: "lastRelaunchAt",
            label: "Relance",
            options: { filter: true, sort: true }
        },
        {
            name: "personalPaymentTypeName",
            label: "Type de paiement",
            options: { filter: true, sort: true }
        },
        {
            name: "litigeStripeSpan",
            label: "Litige stripe",
            options: { filter: true, sort: true }
        },
        {
            name: "billingPrice",
            label: "Prix",
            options: { filter: true, sort: true }
        },
        {
            name: "personalPaymentAt",
            label: "Date de paiement",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];
    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Visualiser la fiche client'} placement="left">
                    <IconButton onClick={() => props.history.push(getRoutePathname('crm_prospect', { id: row.prospect }))}>
                        <Assignment />
                    </IconButton>
                </Tooltip>

                {!row.availableAt && !row.personalPaymentAt && !row.billingPrice &&
                    <Tooltip title={'Relance de paiement'} placement="left">
                        <IconButton onClick={() => {
                            setShow(true);
                            setRow(row);
                            handler.setValue('relaunchAt', moment());
                        }}>
                            <Send />
                        </IconButton>
                    </Tooltip>
                }
            </>
        );
    };

    // Search
    const [formSearch, setFormSearch] = React.useState({
        organism: {
            name: 'organism',
            label: 'Organisme',
            textHelper: 'Trier par organisme.',
            type: 'text',
            defaultValue: cacheTableServer['crm_personal_relaunch_registration_list'] && cacheTableServer['crm_personal_relaunch_registration_list'].organism ? cacheTableServer['crm_personal_relaunch_registration_list'].organism : 'all',
            options: {}
        },
        formationGroup: {
            name: 'formationGroup',
            label: 'Groupe de formation',
            textHelper: 'Trier par groupe de formation.',
            type: 'text',
            defaultValue: cacheTableServer['crm_personal_relaunch_registration_list'] && cacheTableServer['crm_personal_relaunch_registration_list'].formationGroup ? cacheTableServer['crm_personal_relaunch_registration_list'].formationGroup : 'all',
            options: {}
        },
        personalPaymentType: {
            name: 'personalPaymentType',
            label: 'Type de paiement',
            textHelper: 'Trier par type de paiement.',
            type: 'text',
            defaultValue: cacheTableServer['crm_personal_relaunch_registration_list'] && cacheTableServer['crm_personal_relaunch_registration_list'].personalPaymentType ? cacheTableServer['crm_personal_relaunch_registration_list'].personalPaymentType : 'all',
            options: {}
        },
        state: {
            name: 'state',
            label: 'État',
            textHelper: 'Trier par état.',
            type: 'text',
            defaultValue: cacheTableServer['crm_personal_relaunch_registration_list'] && cacheTableServer['crm_personal_relaunch_registration_list'].state ? cacheTableServer['crm_personal_relaunch_registration_list'].personalPaymentType : 'all',
            options: {}
        },
    });
    const handlerSearch = formHandlerInit(formSearch, setFormSearch);

    const [form, setForm] = React.useState({
        relaunchAt: {
            name: 'relaunchAt',
            label: 'Date de la relance',
            textHelper: 'Choisissez une date de la relance.',
            type: 'date',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        comment: {
            name: 'comment',
            label: 'Commentaire',
            textHelper: 'Ajouter un commentaire.',
            type: 'text',
            defaultValue: '',
            options: {}
        }
    });
    const handler = formHandlerInit(form, setForm);

    const inputSearchChange = (index, value) => {
        handlerSearch.setValue(index, value);
        cacheTablePage['crm_personal_relaunch_registration_list'] = 0;
        cacheTableServer['crm_personal_relaunch_registration_list'].page = 0;
        cacheTableServer['crm_personal_relaunch_registration_list'][index] = value;
        dispatch(TableReload('crm_personal_relaunch_registration_list'))
    };

    const changeSearchSlider = (direction = null) => {
        let newStartAt;
        let newEndAt;

        switch (period) {
            case 0: { // Journalière
                if (direction === 'right') newStartAt = moment(startAt).add(1, 'days');
                else if (direction === 'left') newStartAt = moment(startAt).subtract(1, 'days');
                else newStartAt = startAt;

                newEndAt = moment(newStartAt.endOf('day').format());
                newStartAt = moment(newStartAt.startOf('day').format());

                setTextSearchSlider(newStartAt.format('dddd LL'));
                break;
            }
            case 1: { // Hebdomadaire
                if (direction === 'right') newStartAt = moment(startAt).add(1, 'week');
                else if (direction === 'left') newStartAt = moment(startAt).subtract(1, 'week');
                else newStartAt = startAt;

                newEndAt = moment(newStartAt.endOf('week').format());
                newStartAt = moment(newStartAt.startOf('week').format());

                setTextSearchSlider(newStartAt.format('dddd LL') + ' - ' + newEndAt.format('dddd LL'));
                break;
            }
            case 2: { // Mensuelle
                if (direction === 'right') newStartAt = moment(startAt).add(1, 'month');
                else if (direction === 'left') newStartAt = moment(startAt).subtract(1, 'month');
                else newStartAt = startAt;

                newEndAt = moment(newStartAt.endOf('month').format());
                newStartAt = moment(newStartAt.startOf('month').format());

                setTextSearchSlider(newStartAt.format('MMMM YYYY'));
                break;
            }
            case 3: { // Annuelle
                if (direction === 'right') newStartAt = moment(startAt).add(1, 'year');
                else if (direction === 'left') newStartAt = moment(startAt).subtract(1, 'year');
                else newStartAt = startAt;

                newEndAt = moment(newStartAt.endOf('year').format());
                newStartAt = moment(newStartAt.startOf('year').format());

                setTextSearchSlider(newStartAt.format('YYYY'));
                break;
            }
            case 4: { // Personnalisée par plage
                newEndAt = endAt;
                newStartAt = startAt;
                break;
            }
            default: {
                newStartAt = null;
                newEndAt = null;
                break;
            }
        }
        setStartAt(newStartAt);
        setEndAt(newEndAt);
    };

    const periodChange = (event) => {
        setStartAt(moment().startOf('day'));
        setPeriod(parseInt(event.target.value));
    }

    // UseEffect
    React.useEffect(() => {
        dispatch(TableReload('crm_personal_relaunch_registration_list'))
    }, [startAt]);

    React.useEffect(changeSearchSlider, [period]);
    React.useEffect(handlerSearch.start, []);
    React.useEffect(handler.start, []);
    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Suivi des inscriptions',
            context: 'CRM',
            description: 'Liste des inscriptions en financement personnel.'
        });
        Api.get({
            route: 'select_organisms',
            data: { 'isFull': true }
        },
            (response) => {
                if (response && response.data) {
                    let data = [];
                    data.push({ value: 'all', label: 'Tous' });
                    for (let index in response.data) {
                        if (inArray(response.data[index].value, authenticationReducer.organisms)) {
                            data.push(response.data[index]);
                        }
                    }
                    setOptionOrganisms(data);
                }
            });
        Api.get({
            route: 'select_formation_groups'
        },
            (response) => {
                if (response && response.data) {
                    let data = [{ value: 'all', label: 'Tous' }, ...response.data];
                    setOptionFormationGroup(data);
                }
            });
        Api.get({
            route: 'select_personal_payment_types'
        },
            (response) => {
                if (response && response.data) {
                    let data = [{ value: 'all', label: 'Tous' }, ...response.data];
                    setOptionPersonalPaymentType(data);
                }
            });
        setReadyContent(true);
    }, []);
    React.useEffect(() => {
        if (screenReducer.screen) {
            let isTallScreen = (screenReducer.screen === 'MD' || screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
            if (isTallScreen !== isTall) setIsTall(isTallScreen);
        }
    }, [screenReducer.screen]);

    const sendRelaunch = () => {
        if (handler.checkError()) {
            console.log('Error');
        }
        else {
            let data = handler.getData();
            dispatch(LinearProgressMainLayoutActivate());
            handler.setFormLoading(true);
            setLoading(true);
            Api.post({
                route: 'crm_personal_relaunch_registration_mail',
                data: { relaunchAt: data.relaunchAt, comment: data.comment },
                params: { registration: row.id }
            },
                (response) => {
                    dispatch(LinearProgressMainLayoutDeactivate());
                    dispatch(TableReload('crm_personal_relaunch_registration_list'));
                    handler.setFormLoading(false);
                    setLoading(false);

                    if (response.status === 200) {
                        setShow(false);
                        setRow(null);
                        dispatch(
                            SnackbarOpen({
                                text: 'Relance envoyée.',
                                variant: 'success',
                            })
                        );
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    };

    const getDownloadExcel = () => {
        setLoadDownload(true);
        let datas = handlerSearch.getData();
        let filters = {
            organism: (datas.organism === 'all') ? '' : datas.organism,
            formationGroup: (datas.formationGroup === 'all') ? '' : datas.formationGroup,
            personalPaymentType: (datas.personalPaymentType === 'all') ? '' : datas.personalPaymentType,
            state: (datas.state === 'all') ? '' : datas.state,
            startAt: startAt.format('YYYY-MM-DD HH:mm:ss'),
            endAt: endAt.format('YYYY-MM-DD HH:mm:ss'),
        };
        Api.getDownload({
            route: 'crm_personal_relaunch_registration_list',
            filename: 'export_liste_inscription_personnel.xls', 
            data: filters
        }, (response) => {
            if (response.status === 200) {
                setLoadDownload(false)
            }
        })
    }

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <Slide direction="left" in={true} style={{ transitionDelay: '0ms' }} {...{ timeout: 500 }}>
                <div>
                    <ShadowBoxComponent>
                        <TitleComponent title={<><FilterListIcon className={classes.iconH3} />Filtre</>} />
                        <Grid container spacing={3}>
                            <Grid item xs={6} sm={6} md={3} lg={3}>
                                <SelectComponent name={'organism'} handler={handlerSearch} options={optionOrganisms} onChange={(val) => inputSearchChange('organism', val)} />
                            </Grid>
                            <Grid item xs={6} sm={6} md={3} lg={3}>
                                <SelectComponent name={'formationGroup'} handler={handlerSearch} options={optionFormationGroup} onChange={(val) => inputSearchChange('formationGroup', val)} />
                            </Grid>
                            <Grid item xs={6} sm={6} md={3} lg={3}>
                                <SelectComponent name={'personalPaymentType'} handler={handlerSearch} options={optionPersonalPaymentType} onChange={(val) => inputSearchChange('personalPaymentType', val)} />
                            </Grid>
                            <Grid item xs={6} sm={6} md={3} lg={3}>
                                <SelectComponent name={'state'} handler={handlerSearch} options={optionStates} onChange={(val) => inputSearchChange('state', val)} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4}>
                                <FormControl variant="outlined" style={{ width: '100%' }}>
                                    <InputLabel htmlFor="outlined-age-native-simple">Période</InputLabel>
                                    <Select
                                        native
                                        value={period}
                                        onChange={periodChange}
                                        label="Période"
                                        inputProps={{ name: 'Période', id: 'outlined-age-native-simple' }}
                                        className={classes.selectPeriod}
                                    >
                                        <option value={0}>Journalière</option>
                                        <option value={1}>Hebdomadaire</option>
                                        <option value={2}>Mensuelle</option>
                                        <option value={3}>Annuelle</option>
                                        <option value={4}>Personnalisée par plage</option>
                                    </Select>
                                </FormControl>
                            </Grid>
                            {period !== 4 ?
                                <Grid item xs={12} sm={12} md={8} lg={8} style={{ position: 'relative' }}>
                                    <ArrowBackIosIcon className={classes.arrowLeftSearchSlider} onClick={() => changeSearchSlider('left')} />
                                    <div className={classes.textSearchSlider}>{textSearchSlider}</div>
                                    <ArrowForwardIosIcon className={classes.arrowRightSearchSlider} onClick={() => changeSearchSlider('right')} />
                                </Grid> :
                                <>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <KeyboardDatePicker
                                            className={classes.datepicker}
                                            autoOk
                                            inputVariant="outlined"
                                            margin="normal"
                                            id="startAt"
                                            label="Début"
                                            format="dd/MM/yyyy"
                                            value={startAt}
                                            onChange={(date) => setStartAt(moment(date).startOf('day'))}
                                            KeyboardButtonProps={{ 'aria-label': 'change date' }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4} lg={4}>
                                        <KeyboardDatePicker
                                            className={classes.datepicker}
                                            autoOk
                                            inputVariant="outlined"
                                            margin="normal"
                                            id="endAt"
                                            label="Fin"
                                            format="dd/MM/yyyy"
                                            value={endAt}
                                            onChange={(date) => setEndAt(moment(date).endOf('day'))}
                                            KeyboardButtonProps={{ 'aria-label': 'change date' }}
                                        />
                                    </Grid>
                                </>
                            }
                        </Grid>
                    </ShadowBoxComponent>
                </div>
            </Slide>

            <br />

            <TableComponent
                id={'crm_personal_relaunch_registration_list'}
                minHeight={'calc(100vh - 515px)'}
                title={'Inscriptions'}
                columns={columns}
                actionFirst={{
                    label: 'Export excel',
                    disabled: loadDownload,
                    onClick: () => getDownloadExcel()
                }}
                promiseServerData={(resolve, options) => {
                    let datas = handlerSearch.getData();
                    let filters = {
                        limit: options.rowsPerPage,
                        offset: options.page * options.rowsPerPage,
                        sortName: options.sortOrder.name,
                        sortDirection: options.sortOrder.direction,
                        organism: (datas.organism === 'all') ? '' : datas.organism,
                        formationGroup: (datas.formationGroup === 'all') ? '' : datas.formationGroup,
                        personalPaymentType: (datas.personalPaymentType === 'all') ? '' : datas.personalPaymentType,
                        state: (datas.state === 'all') ? '' : datas.state,
                        startAt: startAt.format('YYYY-MM-DD HH:mm:ss'),
                        endAt: endAt.format('YYYY-MM-DD HH:mm:ss'),
                    };

                    Api.get({
                        route: 'crm_personal_relaunch_registration_list',
                        data: filters
                    },
                        (response) => {
                            if (response.status === 200) {
                                let data = response.data.data;
                                for (let index in data) {
                                    data[index].action = getAction(data[index]);
                                    data[index].availableAt = data[index].availableAt ? moment(data[index].availableAt.date).format('ll') : '-';
                                    data[index].availableEndAt = data[index].availableEndAt ? moment(data[index].availableEndAt.date).format('ll') : '-';
                                    data[index].deletedAt = data[index].deletedAt ? moment(data[index].deletedAt.date).format('lll') : '-';
                                    data[index].cancelAt = data[index].cancelAt ? moment(data[index].cancelAt.date).format('lll') : '-';
                                    data[index].personalPaymentAt = data[index].personalPaymentAt ? moment(data[index].personalPaymentAt.date).format('lll') : '-';
                                    data[index].billingPrice = data[index].billingPrice ? data[index].billingPrice + '€' : '-';
                                    data[index].formationName = <span style={{ width: 270, display: 'block' }}>{data[index].formationName}</span>;
                                    data[index].litigeStripeSpan = data[index].inStripeLitige ? <Tooltip title={'Attention litige paiement'}><Warning value='true' color='error' /></Tooltip> : <Tooltip title={'Aucun litige'}><Check className={classes.checkIcon} value='false' color='primary' /></Tooltip>;
                                    data[index].completion = <div style={{ width: 150 }}><LinearProgressWithLabel value={data[index].completion} /></div>;
                                    data[index].lastRelaunchAt = data[index].relaunchs.length > 0 ? <div style={{ width: 140 }}>
                                        <Tooltip title={
                                            <span>{data[index].relaunchs.map((relaunch, i) =>
                                                <div key={i}>
                                                    <p style={{ marginBottom: 0 }}>{relaunch.by} : Relance {relaunch.type} le {moment(relaunch.date).format('lll')}</p>
                                                    {relaunch.comment && <p style={{ margin: 0, marginLeft: 10 }}>*Commentaire : {relaunch.comment}</p>}
                                                </div>)}
                                            </span>} placement="left">
                                            <Info style={{ display: 'inline-block', verticalAlign: 'bottom', margin: '0 5px', color: '#2190ff' }} />
                                        </Tooltip>
                                        <span>{data[index].lastRelaunchAt ? moment(data[index].lastRelaunchAt).format('lll') : ''}</span>
                                    </div> : '-';
                                }
                                resolve(data, response.data.count);
                            } else {
                                resolve([]);
                            }
                        });
                }}
                search={false}
            />

            <br />

            <Dialog open={show}>
                <DialogTitle style={{ fontSize: 15 }}>Envoyer une relance de paiement <br />Inscription - {row ? row.id : ''}</DialogTitle>
                <DialogContent>
                    <div>
                        {(row && row.lastRelaunchAt != "-") &&
                            <p style={{margin: 0, paddingBottom: 20, fontSize: 13, textAlign: 'center', color: '#f44336'}}><ErrorOutline style={{marginRight: 5, verticalAlign: 'sub'}} color='error'/>La dernière relance a été effectuée le : {moment(row.lastRelaunchAt).format('ll')}</p>
                        }
                        <DatePickerComponent name={'relaunchAt'} handler={handler} />
                        <TextFieldComponent name={'comment'} handler={handler} />
                    </div>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent disabled={loading} onClick={() => { setShow(false); setRow(null); }} color={'#5E6E82'} label={'Annuler'} />
                    <ButtonComponent disabled={loading} onClick={sendRelaunch} label={'Envoyer'} />
                </DialogActions>
            </Dialog>

            <br />

        </ContentViewComponent>
    );
}

const useStyles = makeStyles({
    h3: {
        color: '#5E6E82',
        fontSize: 18,
        fontWeight: 300,
        margin: '0px 0px 10px 0px',
        padding: '0px 5px',
    }, iconH3: {
        fontSize: 25,
        padding: '0px 5px',
        verticalAlign: 'sub',
        float: 'right'
    },
    checkIcon: {
        '&.MuiSvgIcon-colorPrimary': {
            color: green[600]
        }
    },
    iconH3: {
        fontSize: 25,
        padding: '0px 5px',
        verticalAlign: 'sub',
        float: 'right'
    },
    selectPeriod: {
        '& select': {
            padding: 10
        }
    },
    datepicker: {
        margin: 0,
        width: '100%',
        '& input': {
            padding: '10px 0px 10px 10px'
        },
        '& .MuiInputBase-root': {
            padding: 0
        },
        '& svg': {
            fontSize: 20
        }
    },
    arrowLeftSearchSlider: {
        fontSize: 25,
        position: 'absolute',
        left: 0,
        top: 0,
        padding: '20px 20px',
        cursor: 'pointer'
    },
    arrowRightSearchSlider: {
        fontSize: 25,
        position: 'absolute',
        right: 0,
        top: 0,
        padding: '20px 20px',
        cursor: 'pointer'
    },
    textSearchSlider: {
        fontSize: 16,
        textAlign: 'center',
        height: 40,
        lineHeight: '40px',
        textTransform: 'capitalize'
    },
});

export default PersonalRelaunchList;
